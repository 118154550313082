import React from "react"
import { graphql, Link } from "gatsby"
import { css } from "@emotion/react"
import { rhythm } from "../utils/typography"
import SiteLayout from "./SiteLayout"
import PageSEO from "./PageSEO"

const wrapper = css`
  display: flex;
  margin-bottom: ${rhythm(0.5)};

  dt {
    margin-right: 5px;
  }

  dd {
    margin-bottom: 0;
  }
`

export default function PsalmTemplate({ data }) {
  const page = data.markdownRemark
  return (
    <SiteLayout>
      <PageSEO title={page.frontmatter.title} />
      <div>
        <h2>
          <Link to="/music-and-lyrics/">{"<"}</Link> {page.frontmatter.title}
        </h2>
        <Summary page={page} />
        <div dangerouslySetInnerHTML={{ __html: page.html }} />
      </div>
    </SiteLayout>
  )
}

function Summary({ page }) {
  const { composed, mode, meter } = page.frontmatter

  if (!composed && !mode && !meter) {
    return null
  }

  return (
    <dl>
      {composed && (
        <div css={wrapper}>
          <dt>Tune composed in:</dt>
          <dd>{page.frontmatter.composed}</dd>
        </div>
      )}
      {mode && (
        <div css={wrapper}>
          <dt>Mode:</dt>
          <dd>{page.frontmatter.mode}</dd>
        </div>
      )}
      {meter && (
        <div css={wrapper}>
          <dt>Meter:</dt>
          <dd>{page.frontmatter.meter}</dd>
        </div>
      )}
    </dl>
  )
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        composed
        mode
        meter
      }
    }
  }
`
